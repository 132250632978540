import { BrowserRouter, Switch, Route } from "react-router-dom";
import ContactUs from "./screen/contact_us";
import Home from "./screen/home";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/contactus" render={() => <ContactUs />} />
        <Route path="/" render={() => <Home />} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
