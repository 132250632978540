import React, { useState } from "react";
import { getComaPrice } from "../module/simple";
import { BikePopup } from "./popups";

function ImageViewer({ imgs, setimgcount, imgcount }) {
  const allImg = imgs.split("|");
  const [show, setshow] = useState(false);
  return (
    <div className="bk1_dt1_caca">
      <BikePopup show={show} close={() => setshow(false)} imgs={allImg} />

      <div className="bk1_dt1_cacab">
        <img
          alt="Img"
          onClick={() => setshow(true)}
          className="bk1_dt1_cacaba"
          src={"https://bajajadmin.kalyaniktm.com/" + allImg[imgcount]}
        />
        <div className="bk1_dt1_cacabb">
          {allImg.slice(0, 7).map((img, k) => (
            <img
              key={k}
              alt="Img"
              style={imgcount === k ? { borderWidth: 3, margin: 2 } : {}}
              onClick={() => setimgcount(k)}
              src={"https://bajajadmin.kalyaniktm.com/" + img}
              className="bk1_dt1_cacabba"
            />
          ))}
        </div>
      </div>
    </div>
  );
}

function BikeDetails({
  varients,
  varient,
  color,
  setvarient,
  setenquiry,
  setimgcount,
  imgcount,
  emiclicked,
}) {
  const bike = varients[varient].products[color];
  const [showemi, setshowemi] = useState(false);
  const [emicolor, setemicolor] = useState(false);
  const [month, setmonth] = useState(60);
  const [intrest, setintrest] = useState(8);

  const emi = (
    (((bike.price * intrest) / 100) * (month / 12) + bike.price) /
    month
  ).toString();

  return (
    <div className="center" id="overview">
      <div className="bk1_dt1_a">
        <div className="bk1_dt1_aa">
          <div className="bk1_dt1_aaa">{bike.name}</div>
          <div className="bk1_dt1_aab">4.6 </div>
          {/* <div className="bk1_dt1_aac">12 Reviews</div> */}
        </div>

        <div className="bk1_dt1_c">
          <div className="bk1_dt1_ca">
            <ImageViewer
              imgs={bike.image}
              setimgcount={setimgcount}
              imgcount={imgcount}
            />
          </div>
          <div className="bk1_dt1_cb">
            {/* <div className="bk1_dt1_cba">
              <div className="bk1_dt1_cbaa">Key specs</div>
              <div className="bk1_dt1_cbaa">349 cc</div>
              <div className="bk1_dt1_cbaa">5 Speed Manual</div>
              <div className="bk1_dt1_cbaa">195 k</div>
            </div> */}
            <div className="bk1_dt1_cbb">
              <div className="bk1_dt1_cbba">
                <div className="bk1_dt1_cbbaa">Version</div>
                <select
                  className="bk1_dt1_cbbab"
                  onChange={(e) => setvarient(e.target.value)}
                >
                  {varients.map((v, k) => (
                    <option key={k} value={k}>
                      {v.title}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="bk1_dt1_cbba">
                <div className="bk1_dt1_cbbaa">Location</div>
                <div className="bk1_dt1_cbbab">Mysor Rd</div>
              </div> */}
            </div>
            <div className="bk1_dt1_cbc">Avg. Ex-showroom price</div>
            <div className="bk1_dt1_cbd">₹ {getComaPrice(bike.price)} /-</div>
            <div className="bk1_dt1_cbiab" onClick={() => setenquiry(0)}>
              &nbsp;Get On-road Price
            </div>
            <div className="row">
              <div className="bk1_dt1_cbe" onClick={() => setenquiry(1)}>
                Get New Offer
              </div>
            </div>
            <div className="bk1_dt1_cbf">Powered by</div>
            <div className="bk1_dt1_cbg">Authorised Bajaj Dealer</div>
            <div className="bk1_dt1_cbh " />
            <div className="bk1_dt1_cbi">
              <div className="bk1_dt1_cbia">
                <div
                  className="bk1_dt1_cbiaa"
                  style={emicolor ? { color: "orange" } : {}}
                >
                  EMI₹ {getComaPrice(emi.split(".")[0])} / month
                </div>
                <div className="bk1_dt1_cbiab" onClick={() => setshowemi(true)}>
                  {showemi ? "" : "EMI Calculator"}
                </div>
              </div>
              {emiclicked ? (
                ""
              ) : (
                <div className="bk1_dt1_cbib" onClick={() => setenquiry(2)}>
                  GET EMI OFFER
                </div>
              )}
            </div>
            <div
              className="bk1_dt1_cbj"
              style={{ display: showemi ? "flex" : "none" }}
            >
              <form className="row">
                Intrest (%)
                <input
                  onChange={(e) => {
                    if (e.target.value > 0 && e.target.value < 101)
                      setintrest(e.target.value);
                    setemicolor(true);
                  }}
                  min="8"
                  max="100"
                  type="number"
                  className="bk1_dt1_cbja"
                  step="0.25"
                  defaultValue="8"
                />
                <div style={{ width: "20%" }} />
                Time (Month)
                <select
                  onChange={(e) => {
                    if (e.target.value > 0) setmonth(e.target.value);
                    setemicolor(true);
                  }}
                  className="bk1_dt1_cbja"
                >
                  <option value="60">60 Months</option>
                  <option value="48">48 months</option>
                  <option value="36">36 months</option>
                  <option value="24">24 months</option>
                  <option value="12">12 months</option>
                </select>
                {/* <input
                  onChange={(e) => {
                    if (e.target.value > 0) setmonth(e.target.value);
                    setemicolor(true);
                  }}
                  type="number"
                  className="bk1_dt1_cbja"
                  id="month"
                  defaultValue="60"
                /> */}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BikeDetails;
