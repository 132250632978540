const executeScroll = (id, k, setpage) => {
  setpage(k);
  var element = document.getElementById(id);
  var elementPosition = element.getBoundingClientRect().top;
  window.scrollTo({
    top: window.scrollY + elementPosition - 100,
    behavior: "smooth",
  });
};

export { executeScroll };
