import React, { useState } from "react";

export default function HomeFaq({ faq }) {
  const [tab, settab] = useState(null);

  return (
    <div className="center">
      <div className="hm1_qn1_a">
        <div className="cm1_hm_title">Common FAQ</div>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        > */}
        {/* <div style={{ marginTop: "6px", width: "100%" }}> */}
        {faq.map((item, k) => (
          <div
            key={k}
            style={{ cursor: "pointer" }}
            onClick={() => settab(tab === k ? null : k)}
          >
            <div className="hm1_qn1_aba">
              <div className="hm1_qn1_abaa">{item.title}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.761"
                height="5.41"
                viewBox="0 0 8.761 5.41"
              >
                <path
                  id="ic_expand_more_24px"
                  d="M7.732,5.41,4.381,2.066,1.029,5.41,0,4.381,4.381,0,8.761,4.381Z"
                  transform="translate(8.761 5.41) rotate(180)"
                  fill="#071c2c"
                />
              </svg>
            </div>
            <div
              className="hm1_qn1_abab"
              style={{ display: k === tab ? "" : "none" }}
            >
              {item.desc}
            </div>
          </div>
        ))}
      </div>
      {/* </div> */}
    </div>
    // </div>
  );
}
