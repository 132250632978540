export const faq = [
  {
    title: "What is the booking process for Chetak?",
    desc: "As of now Chetak vehicle bookings are done only via Online by selecting dealership names",
  },
  {
    title: "How do I charge a Chetak?",
    desc: "The Chetak electric two wheeler can be charged at any three-pin AC 220V, 5A grounded (earthed) wall socket. If you can charge your Phone, you can charge your Chetak",
  },
  {
    title: "How long does the battery last on Chetak electric scooter?",
    desc: "The Chetak lithium-ion battery is designed to last for 70000 kms which normally takes 7 years, provided the user ensures the lithium-ion battery is kept charged above 15% of capacity and used regularly. We offer a warranty of 3 years or 50000 kms (whichever comes earlier)",
  },
  {
    title: "How far can Chetak electric two-wheeler go on a full charge",
    desc: "The Chetak electric scooter has a class-leading range of 90 km (under IDC as per AIS 040) in Eco Mode on a full charge with a new battery.",
  },
  {
    title: "Can I change vehicle colour after booking?",
    desc: "You can request your dealer for change in colour prior to making final payment. Dealer may accept, at the sole discretion of the dealership, your colour change request as per availability of the requested colour.",
  },
  {
    title: "What is the warranty on Chetak?",
    desc: "Chetak offers a warranty 50000 km or 3 year (whichever comes earlier) on the product and the Lithium-ion battery. The auxillary battery has a warranty of 18 months and tyres have a warranty of 12 months. Warranty is effective from the date of the Chetak sales invoice",
  },
  {
    title: "How do I book test ride?",
    desc: "Click on Book a Test ride option available to select nearby dealer and your preferred date and time to avail test ride. You shall need to register to book a test ride. ",
  },
  {
    title:
      "All test rides are offered only at the designated dealership. After your test ride, we request your feedback online.",
    desc: "Can I make partial payment for booking?",
  },
  {
    title:
      "No. You need to make the entire booking amount payment using single mode of payment.",
    desc: "What are the cancellation charges?",
  },
  {
    title:
      "As per terms and conditions, currently Rs. 1000 is the cancellation charges for your booking.",
    desc: "Can I submit documents directly to dealer?",
  },
  {
    title:
      "With new Chetak, we support and encourage paperless electronic transactions. Hence, it is mandatory to upload the required documents. ",
    desc: "Please carry original documents for verification at Dealership. This is required by Law. ",
  },
  {
    title: "What is the mileage?",
    desc: "The mileage of Bajaj Chetak is 95 Km/Charge",
  },
];
