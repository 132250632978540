import axios from "axios";
// import { baseUrl } from "./api_init";
const formurl = "https://bajajapi.kalyaniktm.com/api/form";

async function getOnrodePrice(e, sucees, type) {
  e.preventDefault();
  if (e.target.id.value.length != 10) {
    alert("Wrong Number");
    return 0;
  }
  axios
    .post(formurl, {
      phone: e.target.id.value,
      name: e.target.name.value,
      bike: "Chetak",
      type,
    })
    .then(() => {
      sucees();
      // setbike(e.target.bike != null ? e.target.bike.value : bike);
      // alert(
      //   "Thanks for your interest, our KTM sales expert team will contact you soon."
      // );
    });
  // close();
  return 0;
}

export { getOnrodePrice };
