import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function HomeBanner({ banners }) {
  return (
    <div>
      <Carousel
        swipeable={true}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={2000}
        showArrows={window.innerWidth < 600 ? false : true}
      >
        {banners.map((ban) => (
          <img className="hm1_tp1_i" src={ban} />
        ))}
      </Carousel>
    </div>
  );
}
