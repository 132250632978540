import BikeBodyHeader from "./bike_body_header";
import BikeColor from "./bike_color";
import BikeDetails from "./bike_details";
import BikePrice from "./bike_price";
import BikeSpecification from "./bike_spes";
import BikeSummery from "./bike_summery";

import React, { Component } from "react";
import { executeScroll } from "../method/bike";

export default class HomeBikes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      bike: [],
      bikes: [],
      loading: true,
      error: "",
      varient: 0,
      color: 0,
      imgcount: 0,
      showEnquiry: -1,
      emiclicked: false,
    };
  }

  render() {
    const setpage = (v) => this.setState({ page: v });
    const setimgcount = (v) => this.setState({ imgcount: v });
    const setemiclicked = () => this.setState({ emiclicked: true });

    const {
      page,
      bike,
      bikes,
      loading,
      error,
      varient,
      color,
      imgcount,
      showEnquiry,
      emiclicked,
    } = this.state;
    return (
      <React.StrictMode>
        <BikeBodyHeader
          executeScroll={(id, k) => executeScroll(id, k, setpage)}
          page={page}
        />
        <BikeDetails
          setenquiry={(v) => this.setState({ showEnquiry: v })}
          setvarient={(v) => this.setState({ varient: v })}
          varients={this.props.bike.varients}
          varient={varient}
          color={color}
          setimgcount={setimgcount}
          imgcount={imgcount}
          emiclicked={emiclicked}
        />
        {/* <BikeColor
          colors={this.props.bike.varients[varient]}
          setcolor={(v) => this.setState({ color: v })}
          cColor={color}
          setimgcount={setimgcount}
        /> */}
        <BikeSpecification
          bike={this.props.bike.varients[varient].products[color]}
          spec={this.props.bike.varients[varient].specifications}
        />
        {/* <BikePrice
          bike={this.props.bike}
          setenquiry={(v) => this.setState({ showEnquiry: v })}
        /> */}
        <BikeSummery bike={this.props.bike.varients[varient].products[color]} />
      </React.StrictMode>
    );
  }
}
