import React, { Component } from "react";
import Footer from "../component/footer";
import Header from "../component/header";
import HomeBanner from "../component/home_banner";
import HomeBikes from "../component/home_bikes";
import HomeBrands from "../component/home_brands";
import HomeFaq from "../component/home_faq";
import HomeOnroudPrice from "../component/home_onroadprice";
import HomeTestride from "../component/home_testride";
import { Errorpage, LoadingPage } from "../component/loading_error";
import getHomedata from "../method/home";

export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      error: "",
      bike: {},
      banners: [],
      brands: [],
      faq: [],
    };
  }

  componentDidMount() {
    getHomedata((v) => this.setState(v));
  }

  render() {
    const { loading, error, bike, banners, brands, faq } = this.state;

    return loading ? (
      <LoadingPage />
    ) : error !== "" ? (
      <Errorpage error={error} />
    ) : (
      <React.StrictMode>
        <Header />
        <HomeBanner banners={banners} />
        <HomeBrands brandsM={brands} />
        <HomeBikes bike={bike} />
        <HomeTestride />
        <HomeFaq faq={faq} />
        <HomeOnroudPrice />
        <Footer />
      </React.StrictMode>
    );
  }
}
