import axios from "axios";
import { faq } from "../module/static_data";

const getbikes = "https://bajajapi.kalyaniktm.com/api/chetak?name=chetak";
const getbrands = "https://bajajapi.kalyaniktm.com/api/category";


const b1 = "https://bajajapi.kalyaniktm.com/upload/chetak/1.png";
const b2 = "https://bajajapi.kalyaniktm.com/upload/chetak/2.png";
const b3 = "https://bajajapi.kalyaniktm.com/upload/chetak/3.jpg";
const b4 = "https://bajajapi.kalyaniktm.com/upload/chetak/4.jpg";

async function getBikes(setstate) {
  await axios
    .get(getbikes)
    .then((res) => setstate({ bike: res.data[0] }))
    .catch(() => setstate({ error: "Not Fount Bikes" }));
  return;
}
async function getBanners(setstate) {
  setstate({ banners: [b1, b2, b3, b4] });
  return;
}
async function getBrands(setstate) {
  await axios
    .get(getbrands)
    .then((res) => setstate({ brands: res.data }))
    .catch(() => setstate({ error: "Not Fount Brands" }));
  return;
}
async function getFaq(setstate) {
  setstate({ faq: faq });
  return;
}

export default async function getHomedata(setstate) {
  setstate({ loading: true });
  await getBikes(setstate);
  await getBanners(setstate);
  await getBrands(setstate);
  await getFaq(setstate);
  setstate({ loading: false });
}
