import React from "react";
import appLogo from "../asset/appLogo.png";
import brandLogo from "../asset/brandLogo.png";
import telephone from "../asset/telephone.png";

export default function Header() {
  return (
    <div className="cm1_hd1_a">
      <img src={appLogo} alt="KAlyani" className="cm1_hd1_aa" />
      <div className="cm1_hd1_ab">
        <div className="cm1_hd1_aba">
          <img src={telephone} alt="KAlyani" className="cm1_hd1_abaa" />
          80956 99900
        </div>
        <div className="cm1_hd1_abb">
          <img src={brandLogo} alt="KAlyani" className="cm1_hd1_abba" />
        </div>
      </div>
    </div>
  );
}
