import React, { useState } from "react";
import tickIcon from "../asset/check.JPG";
import checkAnim from "../asset/checkAnim.gif";
import { getOnrodePrice } from "../module/methord";

function HomeTestride() {
  const [booked, setbooked] = useState(0);
  return (
    <div className="center">
      <div className="hm2_Container">
        <div className="hm2_left-div">
          {booked === 0 ? (
            <div className="cm1_hm_title">Book a Test drive</div>
          ) : (
            <></>
          )}
          {booked !== 0 ? (
            booked === 1 ? (
              <img
                alt="Img"
                style={{ padding: "20px 103px", width: 100 }}
                src={checkAnim}
              />
            ) : (
              <img
                alt="Img"
                style={{ padding: "20px 103px", width: 100 }}
                src={tickIcon}
              />
            )
          ) : (
            <form
              id="booking_form1"
              onSubmit={(e) =>
                getOnrodePrice(
                  e,
                  () => {
                    setbooked(1);
                    setTimeout(() => setbooked(2), 1700);
                  },
                  "Test drive"
                )
              }
            >
              <input
                placeholder="Enter Your Name"
                className="hm2_input_tab"
                type="text"
                id="name"
                required
              />
              <input
                placeholder="Enter Your Phone Number"
                className="hm2_input_tab"
                type="text"
                id="id"
                required
              />

              <button type="submit" className="hm2_btn">
                Check on-road price
              </button>
            </form>
          )}
          {booked !== 0 ? (
            <p
              style={{
                padding: "0px 4px",
                fontSize: 14,
                color: "#4d5057",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Thank you for your intrest in Chetak We will get in touch with you
              soon...
            </p>
          ) : (
            ""
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}

export default HomeTestride;
